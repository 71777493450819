import React from 'react'

const Informacoes = () => {
  return (
    <section>
      <div className='container'>
        <div className='col-12 col-lg-8 px-0'>
          <p>
            O Canal de Denúncia do Banco Inter tem o objetivo de receber comunicados
            e registros, por pessoas físicas ou jurídicas, sobre situações ilegais de
            qualquer natureza, relacionadas às atividades e serviços disponibilizados.
          </p>
          <p>
            Este é um canal seguro, que garante o anonimato do denunciante, para comunicação
            de condutas consideradas antéticas, que violem o Código de Conduta e Ética do
            Grupo Inter e/ou legislação vigente.
          </p>
          <p>
            As informações registradas serão recebidas por uma empresa independente e
            especializada, que assegura o sigilo absoluto. Cada solicitação será analisada
            pelo Comitê de Integridade Corporativa, sem conflitos de interesse.
          </p>
          <p className='fs-14 mb-lg-5'>
            Você também pode relatar sua denúncia pelo telefone
            0800 887 0077  - disponível 24 horas, 365 dias por ano.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Informacoes
