import React from 'react'
import { SideBar, ContentWrapper } from 'src/layouts/BaseLayout/style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// components
import CallToAction from 'src/components/CallToAction'
import Informacoes from '../informacoes/_index'

const Sidebar = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

    return (
      <ContentWrapper>
        <div className='col-12 py-5 px-0'>
          <Informacoes />
          <SideBar className='col-12 col-lg-4 px-4 px-lg-3'>
            <div className='position-sticky'>
              <CallToAction 
                section='dobra_02' 
                elementName='Canal de denúncia'
                redirectUrl='https://canaldeetica.com.br/interco/'
                sectionName='Realizar relato'
                elementAction='click button'
                >
                <p className='fw-700'>Faça seu relato online</p>
                <a href='https://canaldeetica.com.br/interco/' 
                  rel='noreferrer' target='_blank' 
                  title='Veja o regulamento' 
                  className='btn btn--orange btn--lg btn--block mb-4'>
                  Realizar relato
                </a>
              </CallToAction>
            </div>
          </SideBar>
        </div>
      </ContentWrapper>
    )
}

export default Sidebar
