import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Wrapper } from './style'

// component
import Header from './sections/header/_index'
import Sidebar from './sections/sidebar/_index'

// PageContext
import pageContext from './pageContext.json'

const CanalDeDenuncia = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Header />
        <Sidebar />
      </Layout>
    </Wrapper>
  )
}

export default CanalDeDenuncia
